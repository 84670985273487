import { push, replace } from 'connected-react-router';
// jl-design-system
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';
import { resetForm } from 'jl-design-system/redux/actions/form/formActions';
import {
  resetAddressSearch,
} from 'jl-design-system/redux/actions/address/addressActions';

import {
  initSavedCollectionPoint,
  getCollectionPointDates,
  getSavedCollectionPoints,
  determinePreSelectedCollectionPoint,
} from '@redux/actions/click-and-collect/clickAndCollectActions';
import {
  initPaymentPage,
  refreshItems,
  getOrderFormData,
  getOrderFormDeliveries,
} from '@redux/actions/payment/orderFormActions';
import { createAddress } from '@redux/actions/addressBook/addressBookActions';
import { fetchPaymentWallet } from '@redux/actions/user/userActions';
import { loadGooglePayScript } from '@redux/actions/payment/googlePayActions';
import errorCodeConstants from '@constants/errorCodeConstants';
import routeConstants from '@constants/routeConstants';
import {
  getAddressFromFormValues,
} from '@utils/address/addressHelpers';
import {
  getDeliveryInstructions,
  isSameAddress,
  getGiftMessageLines,
} from '@utils';
import { getOutletFromDate } from '@utils/delivery/outlet';
import {
  ANALYTICS_DELIVERY_INSTRUCTIONS_SELECTED,
  ANALYTICS_DELIVERY_OPTIONS,
  ANALYTICS_GIFT_MESSAGE_POPULATED,
  ANALYTICS_GIFT_RECEIPT_SELECTED,
  ANALYTICS_PUT_DELIVERY_DETAILS,
  ANALYTICS_SHOW_AGE_CHECK_MODAL,
  ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA,
  CHANGE_DELIVERY_ADDRESS,
  EDIT_DELIVERY_ADDRESS,
  GET_DELIVERY_METHODS,
  GET_DELIVERY_PAGE,
  POST_DELIVERY_PAGE,
  POST_DELIVERY_PAGE_NO_ACTION,
  PUT_DELIVERY_ADDRESS,
  EXPRESS_PUT_DELIVERY_ADDRESS,
  EXPRESS_GET_DELIVERY_METHODS,
  EXPRESS_PUT_DELIVERY_DETAILS,
  PUT_DELIVERY_DETAILS,
  SILENTLY_SELECT_COLLECTION_DATE,
  RESET_DELIVERY_METHODS,
  SET_DELIVERY_BATCHES_PROCESSING,
  SET_SELECTED_DATE_METHOD,
  SET_SELECTED_DATE_METHOD_PAGE,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  SET_SELECTED_SLOT_METHOD,
  SET_SHOW_DELIVERY_ADDRESS_FORM,
  USER_AGE_CHECK,
  ALLOW_INIT_DELIVERY_PAGE_CALLS,
} from '@constants/actionConstants';
import featureConstants from '@constants/featureConstants';
import { DELIVERY_ADDRESS_FORM_ID } from '@utils/form/configs/deliveryAddress';
import deliveryConstants, { DELIVERY_DETAILS_FORM_ID } from '@constants/deliveryConstants';
import {
  URL_GET_DELIVERY_PAGE,
  URL_POST_NEW_DELIVERY_ADDRESS,
  URL_PUT_SAVED_DELIVERY_ADDRESS,
  URL_GET_DELIVERIES_METHODS_V3,
  URL_POST_DELIVERY_PAGE,
  URL_DELIVERY_PAGE,
  URL_PUT_DELIVERY_DETAILS,
} from '@constants/endpointConstants';
import {
  triggerAnalyticsEvent,
  orderFormProductAnalyticsUpdate,
} from '@redux/actions/analytics/analyticsAction';
import { handleSubmitFormValidationErrorAnalytics, processForms } from '@redux/actions/form/formActions';
import { setScrollToDeliveryDetails, getConfiguration } from '@redux/actions/app/appActions';
import {
  recordInitDeliveryPageTimings,
  recordDeliveryMethodTimings,
  saveTimeContinueToPaymentClicked,
} from '@redux/actions/timing/timingActions';
import { getIsFlowersPropositionDisabledSelector } from '@redux/reducers/delivery/deliverySelector';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { canMakeApplePayPayments } from '@redux/actions/payment/applePayActions';
import { showBatchingFailureModal, getItems } from '@redux/actions/edit-basket/editBasketActions';
import { canMakePayPalPayExpressPayments } from '@redux/actions/payment/payPalPaymentActions';
import { ageVerificationProductsSelector } from '@components/age-checker/AgeChecker.state';
import { shouldSetPreferredDeliveryChoice } from '@redux/actions/fulfilment/shouldSetPreferredDeliveryChoice';
import { isClickAndCollectChoice, isDeliveryChoice } from '@utils/delivery/deliveryHelper';
import daysBetweenDateAndStartOfWeek from '@utils/helpers/date/daysBetweenDateAndStartOfWeek';
import { isEmptyObject } from '@utils/object';
import handleInconsistentOrderFormState from '@utils/orderform/handleInconsistentOrderFormState';
import { shouldUseFasterCollection } from './shouldUseFasterCollection';

export const getDeliveryPageV3 = eventDetails => async (dispatch) => {
  const deliveryPageResponse = dispatch({
    type: GET_DELIVERY_PAGE,
    request: client => client({ path: URL_DELIVERY_PAGE, config: { method: 'GET' } }),
    ...eventDetails && { eventDetails },
    deliveriesV3FeatureActive: true,
  });

  await Promise.allSettled([
    dispatch(getOrderFormData({ deliveriesV3FeatureActive: true })),
    dispatch(getOrderFormDeliveries({ method: 'GET' })),
  ]);

  return deliveryPageResponse;
};

export const getDeliveryPage = eventDetails => (dispatch, getState) => {
  if (getState()?.app?.orderComplete ?? false) {
    return {};
  }

  if (isFeatureActive(
    getState(),
    featureConstants.DELIVERY_V3,
  )) return dispatch(getDeliveryPageV3(eventDetails));

  return dispatch({
    type: GET_DELIVERY_PAGE,
    request: client => client({ path: URL_GET_DELIVERY_PAGE, config: { method: 'GET' } }),
    ...eventDetails && { eventDetails },
  });
};

const setUpdatedProductAndPaymentAnalytics = (deliveryMethodsResponse = []) => (dispatch, getState) => {
  const oldPaymentAnalytics = window?.jlData?.checkout?.payment;
  const oldProductAnalytics = window?.jlData?.checkout?.product
    ?? getState().analytics?.analyticsData?.checkout?.product;

  const productAnalytics = deliveryMethodsResponse
    .map((response) =>
      (response?.result?.analytics?.checkout?.product ?? []).map((product) => {
        const foundProduct = oldProductAnalytics.filter((item) => item.sku === product.sku)[0];

        if (foundProduct) {
          return {
            ...foundProduct,
            batchNum: response.batchId,
          };
        }

        return product;
      })).flat();

  return dispatch({
    type: ANALYTICS_UPDATED_PRODUCT_PAYMENT_DATA,
    paymentAnalytics: oldPaymentAnalytics,
    productAnalytics,
  });
};

export const postDeliveryPageV3 = isDeliveryPageInitialised => async (dispatch) => {
  // async dispatch configuration and delivery-page API calls.
  // Both must resolve before we can start rendering delivery page
  const responses = await Promise.allSettled([
    dispatch(getConfiguration()),
    dispatch({
      type: POST_DELIVERY_PAGE,
      request: client => client({ path: URL_DELIVERY_PAGE, config: { method: 'POST' } }),
      ...isDeliveryPageInitialised && { isDeliveryPageInitialised },
      deliveriesV3FeatureActive: true,
    }),
  ]);

  const deliveryPageResponse = responses.find(response => response.value?.type?.includes(POST_DELIVERY_PAGE))?.value;

  if (deliveryPageResponse?.type?.includes('SUCCESS')) {
    // TODO find a way to support shouldSetPreferredDeliveryChoice in this journey
    const orderFormResponses = await Promise.allSettled([
      dispatch(getOrderFormData({ deliveriesV3FeatureActive: true })),
      dispatch(getOrderFormDeliveries()),
    ]);

    dispatch(orderFormProductAnalyticsUpdate(
      deliveryPageResponse?.result?.analytics?.checkout?.payment,
      orderFormResponses[0]?.value?.result?.analytics?.checkout?.product,
    ));
  }

  return deliveryPageResponse;
};

export const postDeliveryPage = isDeliveryPageInitialised => async (dispatch, getState) => {
  const state = getState();
  if (state?.app?.orderComplete ?? false) return {};

  if (isFeatureActive(
    state,
    featureConstants.DELIVERY_V3,
  )) return dispatch(postDeliveryPageV3(isDeliveryPageInitialised));

  const postDeliveryPageDispatchObject = {
    type: POST_DELIVERY_PAGE_NO_ACTION,
    request: client => client({ path: URL_POST_DELIVERY_PAGE, config: { method: 'POST' } }),
  };

  // async dispatch configuration and delivery-page API calls.
  // Both must resolve before we can start rendering delivery page
  const responses = await Promise.allSettled([
    dispatch(getConfiguration()),
    dispatch(postDeliveryPageDispatchObject),
  ]);

  const deliveryPageResponse = responses.find(response => response.value?.type?.includes(POST_DELIVERY_PAGE))?.value;
  const preferredDeliveryChoice = dispatch(shouldSetPreferredDeliveryChoice(deliveryPageResponse));

  if (deliveryPageResponse?.type?.includes('SUCCESS')) {
    const shouldWaitForItems = deliveryPageResponse?.result?.ageRestrictedProducts;
    if (shouldWaitForItems) {
      await dispatch(getItems());
    } else dispatch(getOrderFormData());
  }

  return dispatch({
    ...preferredDeliveryChoice && { preferredDeliveryChoice },
    ...isDeliveryPageInitialised && { isDeliveryPageInitialised },
    ...deliveryPageResponse,
    type: deliveryPageResponse.type.replace(POST_DELIVERY_PAGE_NO_ACTION, POST_DELIVERY_PAGE),
  });
};

export const clearDeliveryDetailsValidationErrors = formId => (dispatch, getState) => {
  dispatch(resetForm(formId, getState().form?.[formId]?.values ?? {}));
};

export const getDeliveryMethodsAnalytics = deliveries => (dispatch) => {
  const analytics = deliveries.map((delivery) => {
    if (!delivery.analytics?.deliveryProposition) {
      return null;
    }

    return {
      ...delivery.analytics,
      deliveryProposition: delivery.analytics.deliveryProposition.map((deliveryProposition, index) => {
        const {
          availabilityByDayDates,
          availabilityByDaySlots,
          ...otherProps
        } = deliveryProposition;

        const updatedDeliveryProposition = otherProps;

        if (availabilityByDayDates || availabilityByDaySlots) {
          // analytics expects an 'availabilityByDay' field but BFF returns either 'availabilityByDayDates' or
          // 'availabilityByDaySlots' depending on the type of calendar.
          // Slots should be an array of arrays, dates is a string
          updatedDeliveryProposition.availabilityByDay = availabilityByDayDates || [availabilityByDaySlots];
        }

        if (availabilityByDaySlots) {
          // GVF analytics expects 'dateRange' to be a string
          updatedDeliveryProposition.dateRange = delivery.dateRange.toString();
        }

        const selected = index === delivery.selectedMethod ? 1 : 0;

        return {
          ...updatedDeliveryProposition,
          selected,
        };
      }),
    };
  });

  dispatch(triggerAnalyticsEvent(ANALYTICS_DELIVERY_OPTIONS, {
    analytics: {
      checkout: {
        batch: analytics,
      },
    },
  }));
};

export const updateDeliveryMethodAnalytics = () => async (dispatch, getState) => {
  dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
};

export function getBatchedDeliveryFormsWithErrorsIds(deliveries = [], state) {
  return deliveries.reduce((acc, batch) => {
    const formId = `${DELIVERY_DETAILS_FORM_ID}-${batch.id}`;
    const form = state.form?.[formId] ?? {};
    const hasErrors = !isEmptyObject(form.syncErrors);
    return hasErrors ? [...acc, formId] : acc;
  }, []);
}

export const setDeliveryBatchesProcessing = isProcessing => ({
  type: SET_DELIVERY_BATCHES_PROCESSING,
  isProcessing,
});

export const putDeliveryDetails = ({ deliveryDetailsPayload, express = false }) => ({
  type: express ? EXPRESS_PUT_DELIVERY_DETAILS : PUT_DELIVERY_DETAILS,
  request: client => client({
    path: URL_PUT_DELIVERY_DETAILS,
    config: { method: 'PUT', body: { deliveries: deliveryDetailsPayload } },
  }),
});

export const getDeliveryDetailsForSubmit = ({ delivery, values = {}, giftReceipt = false, giftMessage = '' }) => {
  const {
    deliveryType,
  } = delivery;

  const { deliveryMethod: formValueDeliveryMethod, ...rest } = values;
  const giftMessageLines = getGiftMessageLines({ giftMessage, giftReceipt });
  const formValues = {
    ...rest,
    deliveryMethod: formValueDeliveryMethod,
    giftMessageLines,
  };

  const isGreenVanFleet = deliveryType ===
    deliveryConstants.GREEN_VAN_FLEET ||
    delivery.type === deliveryConstants.GREEN_VAN_FLEET;

  const deliveryInstructionsPresent = (formValues.deliveryInstructionsOption?.length > 0);

  const deliveryInstructions = isGreenVanFleet && deliveryInstructionsPresent ?
    getDeliveryInstructions(formValues) : {};

  let deliveryDetails;
  let parsedValues;
  try {
    parsedValues = JSON.parse(values[deliveryConstants.DELIVERY_V3_FIELD]);

    deliveryDetails = {
      fulfilment: {
        fulfilmentOfferId: parsedValues.fulfilmentOfferId,
        ...giftMessageLines && { giftMessageLines },
        ...!isEmptyObject(deliveryInstructions) && { deliveryInstructions },
      },
    };
  } catch (e) {
    return {};
  }

  // Get analytics details
  let analyticsEventDetails;

  const selectedMethod = delivery.methods[delivery.selectedMethod || 0];

  if (selectedMethod.fulfilmentOffers.type === deliveryConstants.DELIVERY_TYPE_STANDARD) {
    analyticsEventDetails = {
      deliveryMessage: selectedMethod.description.toLowerCase(),
    };
  } else {
    analyticsEventDetails = {
      deliveryMessage: selectedMethod.description.toLowerCase(),
      firstAvailableDay: daysBetweenDateAndStartOfWeek(
        selectedMethod.fulfilmentOffers.rows.map(r => r.offers.find(o => o.available))[0]?.date,
      ),
      selectedDaysUntilDelivery: daysBetweenDateAndStartOfWeek(parsedValues.date),
    };
  }

  return [{
    deliveryId: delivery.id,
    fulfilment: deliveryDetails.fulfilment,
  }, { ...analyticsEventDetails }];
};

export const submitDeliveryDetails = ({
  shouldRecordDeliveryTimings = false,
} = {}) => async (dispatch, getState) => {
  const state = getState();
  const deliveries = state.delivery.deliveries;

  const formsWithErrorsIds = getBatchedDeliveryFormsWithErrorsIds(deliveries, state);

  if (formsWithErrorsIds.length) {

    let formSyncErrors = {};

    formsWithErrorsIds.forEach((formId) => {
      formSyncErrors[formId] = state.form?.[formId]?.syncErrors;
    });

    const processFormsErrors = await dispatch(processForms(formsWithErrorsIds[0], formsWithErrorsIds));

    formSyncErrors = {
      ...processFormsErrors,
    };

    await dispatch(handleSubmitFormValidationErrorAnalytics(formSyncErrors));
    return;
  }

  dispatch(saveTimeContinueToPaymentClicked());

  dispatch(setDeliveryBatchesProcessing(true));

  const analyticsEventDetails = [];
  const deliveryDetailsPayload = [];

  deliveries.forEach((delivery) => {
    const formId = delivery.deliveryDetailsFormId;
    const form = state.form?.[formId] ?? {};
    const giftReceiptId = formId;

    const returnValues = getDeliveryDetailsForSubmit({
      delivery,
      giftMessage: state.app?.defaultGiftMessageValue?.[giftReceiptId],
      giftReceipt: state.app?.isGiftReceiptSelected?.[giftReceiptId],
      values: form.values,
    });

    deliveryDetailsPayload.push(returnValues[0]);
    if (returnValues.length > 0) {
      analyticsEventDetails.push(returnValues[1]);
    }
  });

  const putDeliveryDetailsResponse = await dispatch(putDeliveryDetails({ deliveryDetailsPayload }));

  if (putDeliveryDetailsResponse.type === `${PUT_DELIVERY_DETAILS}.SUCCESS`) {
    analyticsEventDetails.forEach((analytics) => {
      dispatch(triggerAnalyticsEvent(ANALYTICS_PUT_DELIVERY_DETAILS, {
        ...analytics,
      }));
    });

    dispatch(initPaymentPage({
      pushAfter: true,
      shouldRecordDeliveryTimings,
    }));
  }

  if (
    putDeliveryDetailsResponse.type === `${PUT_DELIVERY_DETAILS}.FAILED` &&
    putDeliveryDetailsResponse?.error?.code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE
  ) {
    const rebatchInProgress = state?.orderForm?.rebatchOrderApiCallActive;
    if (!rebatchInProgress) {
      await dispatch(handleInconsistentOrderFormState());
    }
  }

  dispatch(setDeliveryBatchesProcessing(false));
};

export const putDeliveryAddress = ({ body, isSavedAddress = false, express = false }) => {

  const id = body.addressDetails?.id;
  const url = id
    ? URL_PUT_SAVED_DELIVERY_ADDRESS(id)
    : URL_POST_NEW_DELIVERY_ADDRESS;

  const method = id ? 'PUT' : 'POST';

  const type = express ? EXPRESS_PUT_DELIVERY_ADDRESS : PUT_DELIVERY_ADDRESS;

  return {
    type,
    request: client => client({ path: url, config: { method, body } }),
    address: body.addressDetails,
    isSavedAddress,
  };
};

export const getDeliveryMethods = ({
  address,
  fromDate,
  id,
  refresh,
  dateRange,
  express = false,
}) => (dispatch, getState) => {
  if (refresh) {
    dispatch(resetForm(`${DELIVERY_DETAILS_FORM_ID}-${id}`, {}));
  }

  const outletFromDate = getOutletFromDate({ state: getState(), id, fromDate });
  const flowersFeatureDisabled = getIsFlowersPropositionDisabledSelector(getState());

  return dispatch({
    address,
    type: express ? EXPRESS_GET_DELIVERY_METHODS : GET_DELIVERY_METHODS,
    batchId: id,
    ...fromDate && { fromDate },
    ...outletFromDate && { fromDate: outletFromDate },
    ...refresh && { refresh },
    ...dateRange && { dateRange },
    ...flowersFeatureDisabled && { flowersFeatureDisabled },
    mergeCustomerInfo: true,
    request: client => client({
      path: URL_GET_DELIVERIES_METHODS_V3({
        from: fromDate || outletFromDate,
        id,
      }),
      config: {
        method: 'GET',
      },
    }),
  });
};

export const refreshDeliveryMethods = () => async (dispatch, getState) => {
  const {
    confirmedDeliveryAddress,
    deliveries,
    deliveryContainsIncompatibleServices,
    undeliverableItemsInBasket,
    servicesThatNeedRemoving,
  } = getState().delivery;

  if (
    undeliverableItemsInBasket?.length > 0 ||
    deliveryContainsIncompatibleServices ||
    servicesThatNeedRemoving?.length > 0
  ) {
    // don't get delivery methods until undeliverable items in basket scenario is resolved
    return;
  }

  const getDeliveryMethodsResponse = await Promise.all(deliveries.map(async (delivery) => {
    const {
      deliveryType,
      id,
    } = delivery;

    let fromDate = '';
    if (deliveryType === deliveryConstants.GREEN_VAN_FLEET || delivery.type === deliveryConstants.GREEN_VAN_FLEET) {
      if (!delivery.selectedMethodIsExpress) {
        fromDate = delivery.fromDate;
      }
    }

    const response = await dispatch(getDeliveryMethods({
      address: confirmedDeliveryAddress,
      fromDate,
      id,
    }));

    if (
      response.type === `${GET_DELIVERY_METHODS}.FAILED` &&
      response?.error?.code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE
    ) {
      await dispatch(handleInconsistentOrderFormState());
    }

    return response;
  }));

  dispatch(setUpdatedProductAndPaymentAnalytics(getDeliveryMethodsResponse));

  dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
};

export const setDeliveryAddressAndGetDeliveryMethods = ({
  formValues,
  address,
}) => (dispatch, getState) => (
  new Promise(async (resolve) => {
    const { app, delivery } = getState();

    const isSavedAddress = !!(address?.id || formValues?.id);
    const confirmedDeliveryAddress = delivery?.confirmedDeliveryAddress;
    const shouldSetConfirmedDeliveryAddressAgain = delivery?.shouldSetConfirmedDeliveryAddressAgain;
    const disableSaveDeliveryAddress = (app?.orderComplete ?? false) || (app?.orderProcessing ?? false);

    if (disableSaveDeliveryAddress) {
      resolve({});
      return;
    }

    if (
      !shouldSetConfirmedDeliveryAddressAgain &&
      isSavedAddress &&
      isSameAddress({ formValues, address, addressToCheck: confirmedDeliveryAddress })
    ) {
      resolve({});
      return;
    }

    const addressFromFormValues = getAddressFromFormValues(formValues);
    const body = address ?
      { addressDetails: address } : { addressDetails: addressFromFormValues };

    if (body.addressDetails.invalid) {
      resolve({});
      return;
    }

    const lineItemTotalBeforePutAddress = getState()?.orderForm?.lineItemTotal;
    const putDeliveryAddressResponse = await dispatch(
      putDeliveryAddress({
        body,
        isSavedAddress,
      }),
    );

    if (putDeliveryAddressResponse.type === `${PUT_DELIVERY_ADDRESS}.SUCCESS`) {
      const deliveries = putDeliveryAddressResponse?.result?.orderForm?.deliveries;

      await dispatch(refreshItems(lineItemTotalBeforePutAddress, deliveries));

      const {
        delivery: {
          undeliverableItemsInBasket,
          deliveryContainsIncompatibleServices,
          servicesThatNeedRemoving,
        } = {},
      } = getState();

      if (
        undeliverableItemsInBasket?.length > 0 ||
        deliveryContainsIncompatibleServices ||
        servicesThatNeedRemoving?.length > 0
      ) {
        // don't get delivery methods until undeliverable items in basket scenario is resolved
        dispatch(push(routeConstants.DELIVERY));
        resolve();
        return;
      }

      const getDeliveryMethodsResponse = await Promise.all(deliveries.map(async (delivery) => {
        dispatch(resetForm(delivery.deliveryDetailsFormId));
        const deliveryMethods = await dispatch(getDeliveryMethods({
          address: isEmptyObject(address) ? addressFromFormValues : address,
          id: delivery.id,
          type: delivery.type,
          isSavedAddress,
        }));

        return deliveryMethods;
      }));

      dispatch(setUpdatedProductAndPaymentAnalytics(getDeliveryMethodsResponse));

      dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
    }

    resolve(putDeliveryAddressResponse);
  })
);

export const resetDeliveryMethods = () => ({
  type: RESET_DELIVERY_METHODS,
});

export const resetDeliveryAddressAndMethods = ({ addressId, countryCode }) => async (dispatch) => {
  dispatch(resetDeliveryMethods());
  dispatch(resetAddressSearch({ addressId, countryCode }));
};

export const initSignedInUser = () => async (dispatch, getState) => {
  const user = getState().user || {};
  if (user.isSignedInWithData) {
    const selectedDeliveryAddress = user.selectedDeliveryAddress;

    if (selectedDeliveryAddress) {
      if (
        !selectedDeliveryAddress.invalid &&
        !selectedDeliveryAddress.notAvailableForDelivery
      ) {
        await dispatch(setDeliveryAddressAndGetDeliveryMethods({ address: selectedDeliveryAddress }));
      }
    }
  }
};

export const setCollectionUrl = redirectAction => async (dispatch, getState) => {
  const pathname = getState()?.router?.location?.pathname ?? '';

  if (pathname === routeConstants.EDIT_BASKET) {
    return {};
  }

  if (!pathname.includes(routeConstants.CLICK_AND_COLLECT)) {
    const selectedCollectionPointId = getState()?.clickAndCollect?.selectedCollectionPoint?.id;
    let url;
    if (selectedCollectionPointId) {
      url = routeConstants.CLICK_AND_COLLECT_STORE_SELECTED;
    } else {
      url = routeConstants.CLICK_AND_COLLECT_SEARCH_LIST;
    }

    return dispatch(redirectAction(url));
  }

  return {};
};

// TODO a lot of common functionality between initDelivery and setSelectedDeliveryChoiceId, could possibly be merged
export const initDelivery = ({ refresh = false } = {}) => async (dispatch, getState) => {
  const state = getState();
  const selectedDeliveryChoiceId = state?.delivery?.selectedDeliveryChoiceId;

  if (isDeliveryChoice(selectedDeliveryChoiceId)) {
    // if delivery methods exist in state we can assume
    // user has manually changed URL to get here, so don't redirect
    if (!state.delivery?.deliveries?.some(delivery => delivery.methods)) {
      await dispatch(replace(routeConstants.DELIVERY));
    }

    const confirmedDeliveryAddress = state?.delivery?.confirmedDeliveryAddress;
    const getDeliveryMethodsError = state?.delivery?.getDeliveryMethodsError;
    const putDeliveryAddressError = state?.delivery?.putDeliveryAddressError;

    if (!getDeliveryMethodsError && !putDeliveryAddressError) {
      const hasConfirmedDeliveryAddress = !!confirmedDeliveryAddress;

      if (hasConfirmedDeliveryAddress) {
        const shouldSetConfirmedDeliveryAddressAgain = state?.delivery?.shouldSetConfirmedDeliveryAddressAgain;

        if (shouldSetConfirmedDeliveryAddressAgain) {
          dispatch(setScrollToDeliveryDetails(true));
          await dispatch(setDeliveryAddressAndGetDeliveryMethods({
            address: confirmedDeliveryAddress,
          }));
        } else {
          await dispatch(refreshDeliveryMethods());
          dispatch(recordDeliveryMethodTimings());
        }
      } else {
        dispatch(initSignedInUser());
      }
    }
  }

  if (isClickAndCollectChoice(selectedDeliveryChoiceId)) {
    const fasterCollection = await dispatch(shouldUseFasterCollection());

    if (state.user?.collectionPointsSaved && !state.user.savedCollectionPointsLoaded) {
      const collectionPoint = determinePreSelectedCollectionPoint(state?.user?.collectionPoints ?? []);

      await Promise.all([
        dispatch(getSavedCollectionPoints()),
        dispatch(getCollectionPointDates({
          batchId: state?.delivery?.deliveries?.[0]?.id,
          collectionPointId: collectionPoint?.id,
        })),
      ]);
    }
    await dispatch(setCollectionUrl(replace));
    // TODO see if datesAlreadyLoaded can be removed
    dispatch(initSavedCollectionPoint({ refresh, datesAlreadyLoaded: true, fasterCollection }));
  }

  dispatch(fetchPaymentWallet());

};

export const setSelectedDeliveryChoiceId = id => async (dispatch, getState) => {

  const state = getState();
  const deliveryMethodsApiCallComplete = state.delivery?.deliveryMethodsApiCallComplete;

  dispatch({
    type: SET_SELECTED_DELIVERY_CHOICE_ID,
    id,
    resetUseDeliveryAddressAsBillingAddress: state?.delivery?.selectedDeliveryChoiceId !== id,
  });

  if (id === deliveryConstants.DELIVERY) {
    dispatch(push(routeConstants.DELIVERY));
    dispatch(initDelivery());
  }

  if (id === deliveryConstants.CLICK_AND_COLLECT) {
    if (state.user?.collectionPointsSaved && !state.user?.savedCollectionPointsLoaded) {
      await dispatch(getSavedCollectionPoints());
    }

    await dispatch(setCollectionUrl(push));
    dispatch(initSavedCollectionPoint());

    const selectedCollectionPointId = state?.clickAndCollect?.selectedCollectionPoint?.id;
    if (deliveryMethodsApiCallComplete && selectedCollectionPointId) {
      const batchId = state.delivery.deliveries[0]?.id;

      const fetchedCollectionPointDates = await dispatch(getCollectionPointDates({
        batchId,
        collectionPointId: selectedCollectionPointId,
      }));

      const newSelectedCollectionDateObject = fetchedCollectionPointDates.result.collectionDates.find(
        item => item.date === state.clickAndCollect.selectedCollectionTime,
      );

      if (newSelectedCollectionDateObject) {
        dispatch({
          type: SILENTLY_SELECT_COLLECTION_DATE,
          payload: newSelectedCollectionDateObject.date,
        });
      }

    }
  }
};

export const postDeliveryActions = () => async (dispatch) => {
  dispatch(loadGooglePayScript());
  dispatch(canMakeApplePayPayments());
  dispatch(canMakePayPalPayExpressPayments());
};

export const initDeliveryPage = ({
  forceGetDeliveryPage = false,
  isDeliveryPageInitialised = false,
  refresh = false,
} = {}) => async (dispatch, getState) => {
  // occurs if browser is refreshed OR user navigates via browser controls
  const postOnly = !forceGetDeliveryPage && (getState()?.delivery?.usePostToInitDeliveryPage ?? true);
  const response = await dispatch(postOnly ? postDeliveryPage(isDeliveryPageInitialised) : getDeliveryPage());

  if (response.type === `${GET_DELIVERY_PAGE}.SUCCESS` || response.type === `${POST_DELIVERY_PAGE}.SUCCESS`) {
    if (postOnly) dispatch(recordInitDeliveryPageTimings());

    const ageVerificationProducts = ageVerificationProductsSelector(getState());
    if (ageVerificationProducts?.length > 0) {
      return;
    }

    const { showAgeCheckModal, userDobInvalid, userDob } = getState().user || {};

    if (showAgeCheckModal) {
      dispatch(triggerAnalyticsEvent(ANALYTICS_SHOW_AGE_CHECK_MODAL));
      if (userDobInvalid) {
        dispatch({
          type: `${USER_AGE_CHECK}.FAILED`,
          dob: userDob,
        });
      }
    }

    dispatch(postDeliveryActions());

    await dispatch(initDelivery({ refresh }));
  }

  if (
    response.type === `${GET_DELIVERY_PAGE}.FAILED` &&
    response?.error?.code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE
  ) {
    await dispatch(handleInconsistentOrderFormState());
  }

  if (response.type === `${POST_DELIVERY_PAGE}.FAILED` &&
    response?.error?.code === errorCodeConstants.BATCHING_FAILURE
  ) {
    const batchingFailureIds = response?.error?.inconsistentBatchProducts ?? [];
    await dispatch(showBatchingFailureModal(batchingFailureIds));
  }
};

export const resetIsDeliveryPageInitialised = () => ({
  type: ALLOW_INIT_DELIVERY_PAGE_CALLS,
});

export const getDeliveryMethodsDateRange = ({ id = '', fromDate = '', refresh = false, dateRange = 0 }) => async (dispatch, getState) => {
  await dispatch(getDeliveryMethods({
    id,
    fromDate,
    refresh,
    dateRange,
  }));

  dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
};

export const setSelectedSlotMethod = (displayMethod, express, delivery) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_SLOT_METHOD,
    displayMethod,
    express,
    delivery,
  });

  dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
};

export const setSelectedDateMethod = (displayMethod, deliveryId) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_DATE_METHOD,
    displayMethod,
    deliveryId,
  });

  dispatch(getDeliveryMethodsAnalytics(getState().delivery.deliveries));
};

export const setSelectedDateMethodPage = ({ delivery, method, newPage, shouldAnimate }) => ({
  type: SET_SELECTED_DATE_METHOD_PAGE,
  delivery,
  method,
  newPage,
  shouldAnimate,
});

export const deliveryInstructionsOptionSelected = selection => (dispatch) => {
  dispatch(triggerAnalyticsEvent(ANALYTICS_DELIVERY_INSTRUCTIONS_SELECTED, { subType: selection }));
};

export const editDeliveryAddress = address => async (dispatch) => {
  dispatch(resetForm(DELIVERY_ADDRESS_FORM_ID, getFormInitialValuesFromAddress(address)));
  dispatch({ type: EDIT_DELIVERY_ADDRESS });
};

export const changeDeliveryAddress = () => async (dispatch, getState) => {

  const isSignedInWithData = getState().user?.isSignedInWithData;

  if (isSignedInWithData) {
    dispatch(push(routeConstants.DELIVERY_ADDRESS_BOOK));
    return;
  }

  if (!isSignedInWithData) {
    const confirmedDeliveryAddress = getState().delivery?.confirmedDeliveryAddress;
    const selectedDeliveryAddress = getState().user?.selectedDeliveryAddress;
    const address = confirmedDeliveryAddress || selectedDeliveryAddress;
    const initValues = getFormInitialValuesFromAddress(address);

    dispatch(resetForm(DELIVERY_ADDRESS_FORM_ID, initValues));
    dispatch({ type: CHANGE_DELIVERY_ADDRESS });
  }
};

export const setShowDeliveryAddressForm = show => ({
  type: SET_SHOW_DELIVERY_ADDRESS_FORM,
  show,
});

export const giftReceiptSelected = isGiftReceiptSelected => (dispatch) => {
  dispatch(triggerAnalyticsEvent(ANALYTICS_GIFT_RECEIPT_SELECTED, {
    giftReceiptSelected: isGiftReceiptSelected,
  }));
};

export const giftMessagePopulated = isGiftMessagePopulated => (dispatch) => {
  dispatch(triggerAnalyticsEvent(ANALYTICS_GIFT_MESSAGE_POPULATED, {
    giftMessagePopulated: isGiftMessagePopulated,
  }));
};

export const onUseAddressButtonClick = formValues => async (dispatch, getState) => {
  const isSignedIn = getState().user.isSignedIn;
  // TODO check that formValues contains expected address object as well as existing
  const selectedDeliveryAddress = formValues ?
    getAddressFromFormValues(formValues) :
    getState().user.selectedDeliveryAddress;

  dispatch(setScrollToDeliveryDetails(true));
  if (isSignedIn && !selectedDeliveryAddress.id) {
    return dispatch(createAddress({
      addressPayload: {
        addressee: selectedDeliveryAddress.addressee,
        address: selectedDeliveryAddress.address,
        phoneNumber: selectedDeliveryAddress.phoneNumber,
      },
    }));
  }

  return dispatch(setDeliveryAddressAndGetDeliveryMethods({
    address: selectedDeliveryAddress,
  }));
};
